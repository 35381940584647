import React, { useEffect, useState } from "react";
import "./LinkAccount.css";
import Think from "../../assets/images/Think.png";
import Square from "../../assets/images/square.png";
import Clover from "../../assets/images/clover.png";
import Toast from "../../assets/images/toast.png";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Cross from "../../assets/images/Cross.png";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useStyles } from "../../components/UseStyles";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAppDispatch } from "../../Hooks";
import { setIsLogin, setLocationAdded, setPosConnect, setPosConnectSelected, setStoreLimit } from "../../store/reducers/AuthReducer";
import { set } from "lodash";

function LinkAccount() {
  const classes = useStyles();
  const [modalopen, setModalopen] = useState(false);
  const dispatch = useAppDispatch();
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  const [successModalopen, setSuccessModalopen] = useState(false);
  const [locationsmodal, setLocationsModal] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [profileName, setProfileName]: any = useState("");
  const [profileEmail, setProfileEmail]: any = useState("");
  const [userCode, setUserCode]: any = useState("");
  const [PosConnection, setPosConnection]: any = useState("");
  const [merchantid, setMerchantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [tokenData, setToken] = useState("");
  const [onLocation, setLocation] = useState<null | HTMLElement>(null);
  const [selectedLocations, setSelectedLocations] = useState<{ id: string; name: string }[]>([]);
  const [squareLocations, setSquareLocations]: any = useState([]);
  const [locationsretrievied, setLocationsRetrieved] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [PosAccountStatus, setPosAccountStatus]: any = useState(null);

  const { posConnectSelected, posConnection, location_added, storeLimit, user } = useSelector((state:any)=> state.Auth)

  console.log(user, "USER")

  const PosAccounStatus = async () => {
    try {
      
      const Usercode = localStorage.getItem("UserCode");
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.get_vendor + Usercode
      );
      console.log(response?.data?.vendor_details?.pos_account_status, "pos_account_status")
      if (response.status === true) {
        dispatch(setPosConnectSelected(false))
        dispatch(setPosConnect(response?.data?.vendor_details?.pos_account_status))
        setPosConnection(response?.data?.vendor_details?.pos_account_status)
        dispatch(setLocationAdded(response?.data?.vendor_details?.pos_account?.location_added))
        dispatch(setStoreLimit(response?.data?.plan_details?.store_limit))
        let locations = response?.data?.locations?.map((eachItem: any) => ({
          id: eachItem.id,
          name: eachItem.name
        }));
        setSelectedLocations(locations);
        console.log(response?.data,"LINK")
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(posConnection,location_added, "posConnection")

  useEffect(()=>{
    if(posConnection === 0 || PosConnection === 0){
      navigate("/linkaccount");
    }else if(location_added === 0){
      if(posConnection === 1){
        Locations();
      }
    }else{
      navigate("/dashboard");
    }
  },[posConnection, PosConnection])

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  useEffect(() => {
    PosAccounStatus();
  }, []);

  useEffect(() => {
    PosAccounStatus();
  }, [posConnectSelected]);

  useEffect(() => {
    const User = localStorage.getItem("firstNameCreate");
    setProfileName(User);
    const Email = localStorage.getItem("email");
    setProfileEmail(Email);
    const Usercode = localStorage.getItem("UserCode");
    setUserCode(Usercode);
    console.log(User, Email, Usercode);
    // console.log(profileEmail, profileName, userCode)
  }, []);

  const CloseModal = () => {
    setModalopen(false);
  };

  const SquareModal = (value:string) => {
    // navigate('/square');
    // setModalopen(true);
    if(value === "square"){
      window.location.replace(`https://squareup.com/oauth2/authorize?client_id=sq0idp-4ewBwx4K1DW_cLNzTcuNtg&response_type=code&redirect_uri=https://api.miniosk.com/api/square/callback&state=${userCode}&scope=BANK_ACCOUNTS_READ+CASH_DRAWER_READ+CUSTOMERS_READ+CUSTOMERS_WRITE+DEVICE_CREDENTIAL_MANAGEMENT+EMPLOYEES_READ+EMPLOYEES_WRITE+INVENTORY_READ+INVENTORY_WRITE+ITEMS_READ+ITEMS_WRITE+LOYALTY_READ+LOYALTY_WRITE+MERCHANT_PROFILE_READ+ORDERS_READ+ORDERS_WRITE+PAYMENTS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_WRITE_IN_PERSON+SETTLEMENTS_READ+TIMECARDS_READ+TIMECARDS_WRITE+TIMECARDS_SETTINGS_READ+TIMECARDS_SETTINGS_WRITE+APPOINTMENTS_READ+APPOINTMENTS_WRITE+APPOINTMENTS_BUSINESS_SETTINGS_READ+INVOICES_READ+INVOICES_WRITE+SUBSCRIPTIONS_READ+SUBSCRIPTIONS_WRITE+DISPUTES_READ+DISPUTES_WRITE+GIFTCARDS_READ+GIFTCARDS_WRITE+ONLINE_STORE_SNIPPETS_WRITE+ONLINE_STORE_SNIPPETS_READ+ONLINE_STORE_SITE_READ+PAYMENTS_WRITE_SHARED_ONFILE+APPOINTMENTS_ALL_READ+APPOINTMENTS_ALL_WRITE+MERCHANT_PROFILE_WRITE`);
    }else{
      window.location.replace(`https://sandbox.dev.clover.com/oauth/authorize?client_id=YEWM1P0G61G02&redirect_uri=https://api.miniosk.com/api/clover/callback&response_type=code&scope=MERCHANT_READ,PAYMENT_READ,INVENTORY_READ,INVENTORY_WRITE,INVENTORY_CATEGORY_READ,ORDER_READ,ORDER_WRITE,MERCHANT_INFO,EMPLOYEE_READ&state=${userCode}`);
    }
    dispatch(setPosConnectSelected(true))
    // setLocationsModal(true)
  };

  const AccountLinked = () => {
    setModalopen(false);
    setTimeout(() => {
      setSuccessModalopen(true);
    }, 500);
  };

  const ImportCatalog = () => {
    navigate("/catalog");
  };

  const LinkPosAccount = async () => {
    if (accessToken == "") {
      enqueueSnackbar("The access token field is required.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (merchantid === "" && merchantid.length < 1) {
      enqueueSnackbar("The merchant id field is required.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (clientId === "") {
      enqueueSnackbar("The client id field is required.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (secretKey === "") {
      enqueueSnackbar("The client secret field is required.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else {
      const Params = {
        access_token: accessToken,
        merchant_id: merchantid,
        client_id: clientId,
        client_secret: secretKey,
        pos_name: "square",
        environment: "sandbox",
        user_code: userCode,
      };
      console.log("Params", Params);
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL + ApiServices.link_pos,
          Params
        );
        if (response.message === "success") {
          AccountLinked();
          console.log("API Response:", response);
        } else {
          enqueueSnackbar("Invalid Square Account", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          console.log("API Response:", response);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  const handleLocation = (event: any) => {
    setLocation(event.currentTarget);
  };

  const handleClick = (locationId: string, locationName: string) => {
    const locationExists = selectedLocations.find(location => location.id === locationId);
    if (locationExists) {
      const updatedLocations = selectedLocations.filter(location => location.id !== locationId);
      setSelectedLocations(updatedLocations);
    } else {
      if (selectedLocations.length < storeLimit) {
        const updatedLocations = [...selectedLocations, { id: locationId, name: locationName }];
        setSelectedLocations(updatedLocations);
      } else {
        enqueueSnackbar(`Maximum ${storeLimit} locations allowed.`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.log("Maximum locations allowed.");
      }
    }
  };

  const SelectLocations = () => {
    Locations();
    setSuccessModalopen(false);
    setTimeout(() => {
      setLocationsModal(true);
    }, 500);
  };

  const Locations = () => {
    if (!tokenData) {
      console.error("Token is not available yet.");
      return;
  }
    const apiEndpoint =
      ApiServices.base_URL + ApiServices.square_locations + userCode;
      const token = tokenData;
    axios
      .get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        setSquareLocations(response.data.locations);
        setLocationsModal(true)
        console.log("Square locations Data", response.data.locations);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const SquareLinked = async () => {
    console.log("Clicked");
    const Params = {
      user_code: userCode,
      location_ids: selectedLocations.map(location => location.id),
    };
    console.log("Params", Params);
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.vendor_locations,
        Params
      );
      if (response.status === true) {
        setLocationsModal(false);
        setTimeout(() => {
          setLocationsRetrieved(true);
        }, 500);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const SquareRedirect = () => {
    // localStorage.clear();
    setLocationsRetrieved(false);
    // window.location.reload()
    dispatch(setPosConnect(1))
    // localStorage.setItem("PosAccount", "1");
    PosAccounStatus();
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);

    // setTimeout(() => {
    // dispatch(setIsLogin(false))
    // }, 500);
  };

  const handleDeleteLocation = (locationId: string) => {
    const updatedLocations = selectedLocations.filter(
      (location) => location.id !== locationId
    );
    setSelectedLocations(updatedLocations);
  };

  console.log(selectedLocations)

  return (
    <div className="link-account-container">
      <div className="think-container">
        <img src={Think} alt="think" style={{ width: "15.66vw" }} />
        <h3 className="pos-account">Link your POS account to Get Started</h3>
      </div>
      <div className="pos-accounts-container">
        <div className="account-container">
          <img src={Square} alt="square" style={{ width: "15.34vw" }} />
          <button onClick={()=>SquareModal("square")} className="link-account-btn">
            Link Square Account
          </button>
        </div>
        <div className="account-container">
          <img src={Clover} alt="clover" style={{ width: "15.34vw" }} />
          <button onClick={()=>SquareModal("clover")} className="link-account-btn">
            Link Clover Account
          </button>
        </div>
      </div>

      <Modal
        open={modalopen}
        onClose={CloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "47.73vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="import-title-container">
            <h3 className="import-title">Enter Credentials</h3>
            <img
              onClick={CloseModal}
              src={Cross}
              alt="Cross"
              style={{
                width: "30px",
                height: "32px",
                backgroundColor: "#F34A4A29",
                borderRadius: "33px",
              }}
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">ACCESS TOKEN</h3>
            <input
              placeholder="Access Token"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              className="input-container"
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">MERCHANT ID</h3>
            <input
              placeholder="Merchant ID"
              value={merchantid}
              onChange={(e) => setMerchantId(e.target.value)}
              className="input-container"
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">CLIENT ID</h3>
            <input
              placeholder="Client ID"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              className="input-container"
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">CLIENT SECRET KEY</h3>
            <input
              placeholder="Client Secret Key"
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              className="input-container"
            />
          </div>

          <div className="import-menu-btn">
            <button onClick={LinkPosAccount} className="import-btn">
              Link
            </button>
            <button onClick={CloseModal} className="cancel-btn">
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={successModalopen}
        onClose={() => setSuccessModalopen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "44.8vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <h2 className="success-header">Successfully Linked</h2>
            <p className="success-desc">
              Your square account has been successfully linked
            </p>
            <button
              onClick={SelectLocations}
              className="done-btn"
              style={{ marginTop: "16px" }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={locationsmodal}
        onClose={() => setLocationsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "44.8vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <h2 className="success-header">Successfully Linked</h2>
            <p className="success-desc">
              Your square account has been successfully linked
            </p>
            <p className="success-desc">
              You can choose upto {storeLimit} locations included in your free trail.
            </p>
            <Grid
              onClick={(event: any) => {
                handleLocation(event);
              }}
              item
              xs={12}
              sm={6}
              md={3.5}
              lg={3.5}
              xl={3.5}
            >
              <Box sx={{ marginTop: "16px" }} className={classes?.OverviewMenu}>
                {selectedLocations.length > 0 ? (
                  <>
                    {selectedLocations.map((locationId, index) => (
                      <Chip
                        key={index}
                        label={locationId.name}
                        onDelete={() => handleDeleteLocation(locationId.id)}
                        sx={{
                          margin: "0px",
                          height: "25px",
                          padding: "0px",
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 500, lineHeight: "16px" }}
                    >
                      Select Location
                    </Typography>
                    <KeyboardArrowDownRoundedIcon
                      sx={{ width: "20px", height: "20px", color: "grey" }}
                    />
                  </>
                )}
              </Box>
            </Grid>

            {/* <button onClick={ImportCatalog} className='done-btn' style={{ marginTop: '16px' }}> */}
            <button
              onClick={SquareLinked}
              className="done-btn"
              style={{ marginTop: "16px" }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={locationsretrievied}
        onClose={() => setLocationsRetrieved(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "38.8vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <h2 className="success-header">Locations Retrieved Successfully</h2>
            {/* <p className='success-desc'>Your square account has been successfully linked</p> */}
            <button
              onClick={SquareRedirect}
              className="done-btn"
              style={{ marginTop: "16px" }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal>

      <Popover
        open={Boolean(onLocation)}
        anchorEl={onLocation}
        onClose={() => setLocation(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: { width: `{onLocation?.clientWidth}px`, borderRadius: "8px" },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%", height: "200px", overflow: "auto" }}>
          <TextField
            type="Text"
            placeholder="Search"
            InputProps={{
              style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
              sx: {
                ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                input: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 8px",
                },
              },
            }}
            size="medium"
            fullWidth
          />
          <Box className={classes.DasboardFilterMain} sx={{ pt: 0.5 }}>
            {squareLocations && Array.isArray(squareLocations) ? (
              squareLocations.map((location: any, index: any) => (
                <Box key={index} className={classes.DasboardFilterItem}>
                  <Checkbox
                    disableRipple
                    checked={selectedLocations.some(selectedLocation => selectedLocation.id === location.id)}
                    onChange={() => handleClick(location.id, location.name)}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {location?.name}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                No locations found
              </Typography>
            )}
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

export default LinkAccount;
